import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {fadeInAnimation} from '@shared/helpers/animations';
import {GreenieService} from "@app/core/services/greenie.service";
import {AppComponent} from "@app/app.component";
import {BehaviorSubject} from "rxjs";
import {UserModel} from "@shared/models/user.model";

@Component({
  selector: 'greeenie',
  templateUrl: './greenie.component.html',
  styleUrls: ['./greenie.component.scss'],
  animations: [fadeInAnimation]
})
export class GreenieComponent implements OnInit {
  chatboxId: string = '';
  isReady: boolean;

  rootElement: HTMLElement | null;
  config = {
    sessionExpiresInMinutes: 60,
    buttonSendText: 'Send',
    placeholderText: 'Type your question here...',
    serverErrorMessage: 'Oops! Something went wrong!',
  };

  constructor(private greenieService: GreenieService) {
    this.chatboxId = `greenie-${(Math.random() + 1).toString(36).substring(7)}`;
    this.isReady = false;
    AppComponent.isBrowser.subscribe(isBrowser => {
      if(isBrowser) {
        if (!this.checkSession()) {
          this.createSession();
        } else {
          this.loadSession();
        }
      }
    })
  }

  checkSession() {
    let time = localStorage.getItem('evabot-session')
    if (time != null) {
      return Number(time.split('.')[1]) > new Date().getTime();
    } else {
      return false;
    }
  }

  createSession() {
    this.greenieService.createSession().subscribe(response => {
      if (response?.hash != null) {
        this.addMessage(response.conversationEntryDtoList[0].message);
        const sessionExpireDate = new Date(new Date().getTime() + this.config.sessionExpiresInMinutes * 60000);
        localStorage.setItem('evabot-session', response?.hash + "." + sessionExpireDate.getTime());
        this.isReady = true;
      } else {
        this.handleErrorMessage();
        this.isReady = true;
      }
    }, error => {
      console.error(error);
      this.handleErrorMessage();
      this.isReady = true;
    })
  }

  loadSession() {
    const localSession = localStorage.getItem('evabot-session');
    if (localSession == null) {
      return;
    }
    const [hash] = localSession.split('.');
    let botMessages: string[] = [];
    this.greenieService.loadSession(hash).subscribe(value => {
      if (value != null && value.length != 0) {
        for (const row of value) {
          if (row.side === 'BOT') {
            botMessages.push(row.message);
            continue;
          } else {
            this.addMessage(botMessages.join('<br><br>'));
            botMessages = [];
            this.addMessage(row.message, row.side !== 'BOT')
          }
        }
        for (const row of botMessages) {
          this.addMessage(row)
        }
      } else if (value != null && value?.message == 'System error') {
        this.addMessage(`<b>${this.config.serverErrorMessage}</b>`);
      } else {
        this.createSession();
      }
      this.isReady = true;
    }, error => {
      this.createSession();
      this.isReady = true;
    });
  }

  handleErrorMessage() {
    this.updateLoadingMessage(`<b>${this.config.serverErrorMessage}</b>`);
    this.isReady = true;
  }

  updateLoadingMessage(message: string) {
    const chatbox = document.getElementById(this.chatboxId);
    if (chatbox != null) {
      const messagesContainer = chatbox.getElementsByClassName('evabot-chatbox-messages')[0];
      if (messagesContainer != null && messagesContainer.lastElementChild) {
        const loader = messagesContainer.lastElementChild.getElementsByClassName('evabot-loader')[0];
        if (loader !== undefined) {
          const messageElement = messagesContainer.lastElementChild.getElementsByClassName('evabot-loader')[0].parentElement;
          if(messageElement) {
            messageElement.innerHTML = message;
          }
          this.scrollToBottom();
        } else {
          this.addMessage(message);
        }
      }
    }
  }

  addMessage(message: string, isUser: boolean = false) {
    const chatbox = document.getElementById(this.chatboxId);
    if (chatbox) {
      const messagesContainer = chatbox.getElementsByClassName('evabot-chatbox-messages')[0];
      const messageElement = document.createElement('div');
      messageElement.classList.add('evabot-chatbox-row');
      messageElement.classList.add(isUser ? 'evabot-anim-fadeInRight' : 'evabot-anim-fadeInLeft');
      const messageHTML = document.createElement('div');
      messageHTML.classList.add("evabot-chatbox-message");
      if (isUser == false) {
        messageHTML.classList.add("evabot-eva-message");
      }
      const messageText = document.createElement('p');
      messageText.innerHTML = message;
      messageHTML.appendChild(messageText);
      messageElement.appendChild(messageHTML);
      messagesContainer.appendChild(messageElement);
      this.scrollToBottom();
    }
  }

  scrollToBottom() {
    const chatbox = document.getElementById(this.chatboxId);
    if (chatbox) {
      const messagesContainer = chatbox.getElementsByClassName('evabot-chatbox-messages')[0];
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }

  ngOnInit(): void {
    this.rootElement = document.getElementById('greenie');
    if (this.rootElement) {
      const toggleChatboxButtons = this.rootElement.getElementsByTagName('button');
      toggleChatboxButtons[0].addEventListener('click', this.toggleChatbox.bind(this), false);
      toggleChatboxButtons[1].addEventListener('click', this.toggleChatbox.bind(this), false);
      const chatboxForm = this.rootElement.getElementsByTagName('form')[0];
      chatboxForm.addEventListener('submit', this.handleOnSubmit.bind(this), true);
    }
  }

  handleOnSubmit(e: any) {
    e.preventDefault();
    const message = e.target.getElementsByTagName('input')[0].value;
    if (!this.isReady || !message) return;
    this.isReady = false;
    this.addMessage(message, true);
    e.target.getElementsByTagName('input')[0].value = '';
    setTimeout(() => this.getEvaResponse(message), 300);
    this.updateSession();
  }

  updateSession() {
    if (!this.checkSession()) {
      this.createSession();
      return;
    }

    const localSession = localStorage.getItem('evabot-session');
    if (localSession == null) {
      return;
    }
    const [hash] = localSession.split('.');
    const sessionExpireDate = new Date(new Date().getTime() + this.config.sessionExpiresInMinutes * 60000);
    localStorage.setItem('evabot-session', `${hash}.${sessionExpireDate.getTime()}`);
  }

  getEvaResponse(message: string) {
    setTimeout(() => {
      if (!this.isReady) {
        this.addMessage('<span class="evabot-loader"></span>');
      }
    }, 500);

    const localSession = localStorage.getItem('evabot-session');
    if (localSession == null) {
      return;
    }
    const [hash] = localSession.split('.');
    const data = {
      hash,
      text: message
    };

    this.greenieService.sendText(data).subscribe(value => {
      if (value != null && value.length != 0) {
        // @ts-ignore
        for (let message of value.map(row => row.message)) {
          this.updateLoadingMessage(message);
        }
        this.isReady = true;
      } else {
        this.handleErrorMessage();
      }
    }, error => {
      this.handleErrorMessage();
    })
  }


  toggleChatbox() {
    const chatbox = document.getElementById(this.chatboxId);
    if (chatbox) {
      const isToggled = chatbox.style.display === 'flex';

      if (!isToggled) {
        chatbox.style.display = 'flex';
        chatbox.getElementsByTagName('input')[0].focus();
        this.scrollToBottom();
      } else {
        chatbox.classList.add('evabot-anim-fadeOutDown');
        setTimeout(() => {
          chatbox.style.display = isToggled ? 'none' : 'flex';
          chatbox.classList.remove('evabot-anim-fadeOutDown');
        }, 400);
      }
    }
  }
}
