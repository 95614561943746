export const environment = {
  production: true,
  isGreenieEnabled: false,
  apiUrl: 'https://api.playitgreen.com/pig2/',
  backOfficeUrl: 'https://crm.playitgreen.com/',
  stripePublishableKey: 'pk_live_51HdwYcEOnYB2nTMFkcZX4bC2BEQfImZbtOl3g5rkDgURpHizmeTWti7QZpJnsvSYEHkLOLjQiYVuQGnR8hEM4jBN00X8wLVgOP',
  paymentReturnUrl: 'https://app.playitgreen.com/register/summary',
  homeUrl: 'https://app.playitgreen.com/',
  wordPressUrl: 'https://playitgreen.com/',
  titlePrefix: "Play It Green - ",
    googleTagId : "UA-172930723-1"
};
