import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IconComponent } from './components/_ui/icon/icon.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ButtonComponent } from './components/_ui/button/button.component';
import { LoaderComponent } from './components/_ui/loader/loader.component';
import { StepsComponent } from './components/_ui/steps/steps.component';
import { InputComponent } from './components/_ui/input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpoilerComponent } from './components/_ui/spoiler/spoiler.component';
import { ControlComponent } from './components/_ui/control/control.component';
import { ControlMessageComponent } from './components/_ui/control/control-message.component';
import { CheckboxComponent } from './components/_ui/checkbox/checkbox.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProgressComponent } from './components/_ui/progress/progress.component';
import { GoodCauseItemComponent } from './components/good-cause-list/good-cause-item/good-cause-item.component';
import { UrlHostnamePipe } from './pipes/url-hostname.pipe';
import { GoodCauseListComponent } from './components/good-cause-list/good-cause-list.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { PaymentMethodsSettingsComponent } from './components/payment-methods/settings/payment-methods-settings.component';
import { RadioComponent } from './components/_ui/radio/radio.component';
import { DataManagerComponent } from './components/data-manager/data-manager.component';
import { AddressesInfoComponent } from './components/addresses-info/addresses-info.component';
import { DeliveryInfoComponent } from './components/delivery-info/delivery-info.component';
import { CounterComponent } from './components/_ui/counter/counter.component';
import { NgxMaskModule } from 'ngx-mask';
import { ModalComponent } from './components/_ui/modal/modal.component';
import { AlertComponent } from './components/_ui/alert/alert.component';
import { ConfirmComponent } from './components/_ui/confirm/confirm.component';
import { RouterModule } from '@angular/router';
import { SpoilerBarComponent } from './components/_ui/spoiler-bar/spoiler-bar.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { SelectComponent } from './components/_ui/select/select.component';
import { TabsComponent } from './components/_ui/tabs/tabs.component';
import { TabComponent } from './components/_ui/tabs/tab/tab.component';
import { GalleryComponent } from './components/_ui/gallery/gallery.component';
import { PieChartComponent } from './components/_ui/pie-chart/pie-chart.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {NgxStripeModule} from "ngx-stripe";
import {
  StripeSaveCreditCardComponent
} from "@shared/components/stripe-save-credit-card/stripe-save-credit-card.component";
import {environment} from "@environment/environment";
import {StripePaymentComponent} from "@shared/components/payment/stripe/stripe-payment.component";
import { PlantTreeModalComponent } from './components/_modals/plant-tree-modal/plant-tree-modal.component';
import { GoodCauseModalComponent } from './components/_modals/goodcause-modal/goodcause-modal.component';
import {OverlayLoaderComponent} from "@shared/components/_ui/overlay-loader/overlay-loader.component";
import { ForestGardenComponent } from './components/forest-garden/forest-garden.component';
import {ImpactComponent} from "@shared/components/forest-garden/impact/impact.component";
import {PaymentMethodsListComponent} from "@shared/components/payment-methods/list/payment-methods-list.component";
import {CreditCardListComponent} from "@shared/components/payment-methods/credit-card-list/credit-card-list.component";
import {GiftCardListComponent} from "@shared/components/payment-methods/gift-card-list/gift-card-list.component";
import {AddressFormComponent} from "@shared/components/billing-form/address-form.component";
import { MembersComponent } from './components/forest-garden/members/members.component';
import { DropdownComponent } from './components/_ui/dropdown/dropdown.component';
import { DropdownItemComponent } from './components/_ui/dropdown/dropdown-item.component';
import { AddMemberModalComponent } from './components/_modals/add-member-modal/add-member-modal.component';
import { CampaignsComponent } from './components/forest-garden/campaigns/campaigns.component';
import { ProgressBarComponent } from './components/_ui/progress-bar/progress-bar.component';
import { NewCampaignModalComponent } from './components/_modals/new-campaign-modal/new-campaign-modal.component';
import { SupportersComponent } from './components/forest-garden/supporters/supporters.component';
import { NgPipesModule } from 'ngx-pipes';
import { DatepickerComponent } from './components/_ui/datepicker/datepicker.component';
import { MaterialModule } from './material.module';
import { CalendarHeader } from './components/_ui/datepicker/calendar-header.component';
import { MemberModalComponent } from './components/_modals/member-modal/member-modal.component';
import { EmptyFormControlComponent } from "@shared/components/_ui/empty-form-control-div/empty-form-control.component";
import { JoinCampaignModalComponent } from './components/_modals/join-campaign-modal/join-campaign-modal.component';
import { FinishedCampaignsModalComponent } from './components/_modals/finished-campaigns-modal/finished-campaigns-modal.component';
import { PaginationComponent } from './components/_ui/pagination/pagination.component';
import {PaginationCentralCampaignsComponent} from "@shared/components/_ui/discovery-pagination/pagination-central-campaigns/pagination-central-campaigns.component";
import {PaginationMembersCampaignsComponent} from "@shared/components/_ui/discovery-pagination/pagination-members-campaigns/pagination-members-campaigns.component";
import {WordpressPaginationComponent} from "@shared/components/_ui/wordpress-pagination/wordpress-pagination.component";
import { NewSubscriptionModalComponent } from './components/_modals/new-subscription-modal/new-subscription-modal.component';
import { LeaderboardsComponent } from './components/forest-garden/leaderboards/leaderboards.component';
import { AuthModalComponent } from './components/_modals/auth-modal/auth-modal.component';
import { SubscriptionPaymentModalComponent } from "@shared/components/_modals/subscription-payment-modal/subscription-payment-modal.component";
import { ShopItemsSliderComponent } from './components/shop-items-slider/shop-items-slider.component';
import { ShopItemComponent } from './components/shop-item/shop-item.component';
import {PageNotFoundComponent} from "@shared/components/page-not-found/page-not-found.component";
import { DirectDebitListComponent } from "@shared/components/payment-methods/direct-debit-list/direct-debit-list.component";
import { GocardlessSaveDirectDebitComponent } from "@shared/components/gocardless-save-direct-debit/gocardless-save-direct-debit.component";
import {
  WordpressPaginationLastArticlesComponent
} from "@shared/components/_ui/wordpress-pagination-last-articles/wordpress-pagination-last-articles.component";
import {
  AuthModalWithoutContinueLogoutComponent
} from "@shared/components/_modals/auth-modal-without-continue-logout/auth-modal-without-continue-logout.component";
import {TokenModalComponent} from "@shared/components/_modals/token-modal/token-modal.component";
import {
  SpoilerBarWithPaymentInfoComponent
} from "@shared/components/_ui/spoiler-bar-with-payment-info/spoiler-bar-with-payment-info.component";
import { LazyImgDirective } from './directives/lazy-img.directive';
import {GreenieComponent} from "@shared/components/greenie/greenie.component";

@NgModule({
  declarations: [
    IconComponent,
    PageNotFoundComponent,
    NavigationComponent,
    ButtonComponent,
    LoaderComponent,
    StepsComponent,
    InputComponent,
    SpoilerComponent,
    ControlComponent,
    ControlMessageComponent,
    CheckboxComponent,
    FooterComponent,
    ProgressComponent,
    GoodCauseItemComponent,
    UrlHostnamePipe,
    GoodCauseListComponent,
    TooltipDirective,
    PaymentMethodsSettingsComponent,
    PaymentMethodsListComponent,
    RadioComponent,
    DataManagerComponent,
    AddressesInfoComponent,
    AddressFormComponent,
    DeliveryInfoComponent,
    CounterComponent,
    ModalComponent,
    AlertComponent,
    ConfirmComponent,
    SpoilerBarComponent,
    SpoilerBarWithPaymentInfoComponent,
    LoginFormComponent,
    SelectComponent,
    TabsComponent,
    TabComponent,
    GalleryComponent,
    GreenieComponent,
    PieChartComponent,
    StripeSaveCreditCardComponent,
    GocardlessSaveDirectDebitComponent,
    StripePaymentComponent,
    PlantTreeModalComponent,
    SubscriptionPaymentModalComponent,
    GoodCauseModalComponent,
    OverlayLoaderComponent,
    ForestGardenComponent,
    ImpactComponent,
    MembersComponent,
    DropdownComponent,
    DropdownItemComponent,
    AddMemberModalComponent,
    CampaignsComponent,
    ProgressBarComponent,
    NewCampaignModalComponent,
    SupportersComponent,
    CreditCardListComponent,
    GiftCardListComponent,
    DatepickerComponent,
    CalendarHeader,
    MemberModalComponent,
    TokenModalComponent,
    EmptyFormControlComponent,
    JoinCampaignModalComponent,
    FinishedCampaignsModalComponent,
    PaginationComponent,
    PaginationCentralCampaignsComponent,
    PaginationMembersCampaignsComponent,
    WordpressPaginationComponent,
    WordpressPaginationLastArticlesComponent,
    NewSubscriptionModalComponent,
    LeaderboardsComponent,
    AuthModalComponent,
    AuthModalWithoutContinueLogoutComponent,
    ShopItemsSliderComponent,
    ShopItemComponent,
    DirectDebitListComponent,
    LazyImgDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgxMaskModule.forChild(),
    AngularSvgIconModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripePublishableKey),
    NgPipesModule,
    MaterialModule
  ],
  exports: [
    IconComponent,
    NavigationComponent,
    StepsComponent,
    InputComponent,
    ButtonComponent,
    ReactiveFormsModule,
    FormsModule,
    SpoilerComponent,
    ControlComponent,
    ControlMessageComponent,
    CheckboxComponent,
    FooterComponent,
    GoodCauseItemComponent,
    GoodCauseListComponent,
    TooltipDirective,
    ModalComponent,
    PaymentMethodsSettingsComponent,
    PaymentMethodsListComponent,
    RadioComponent,
    DataManagerComponent,
    AddressesInfoComponent,
    AddressFormComponent,
    DeliveryInfoComponent,
    CounterComponent,
    AlertComponent,
    ConfirmComponent,
    SpoilerBarComponent,
    SpoilerBarWithPaymentInfoComponent,
    LoginFormComponent,
    SelectComponent,
    UrlHostnamePipe,
    TabsComponent,
    TabComponent,
    GalleryComponent,
    PieChartComponent,
    StripePaymentComponent,
    PlantTreeModalComponent,
    SubscriptionPaymentModalComponent,
    GoodCauseModalComponent,
    ForestGardenComponent,
    MembersComponent,
    AddMemberModalComponent,
    ProgressBarComponent,
    NewCampaignModalComponent,
    DatepickerComponent,
    CalendarHeader,
    DropdownComponent,
    DropdownItemComponent,
    LoaderComponent,
    MemberModalComponent,
    TokenModalComponent,
    EmptyFormControlComponent,
    OverlayLoaderComponent,
    PaginationComponent,
    PaginationCentralCampaignsComponent,
    PaginationMembersCampaignsComponent,
    WordpressPaginationComponent,
    WordpressPaginationLastArticlesComponent,
    NewSubscriptionModalComponent,
    AuthModalComponent,
    AuthModalWithoutContinueLogoutComponent,
    ShopItemComponent,
    ShopItemsSliderComponent,
    PageNotFoundComponent,
    GocardlessSaveDirectDebitComponent,
    GreenieComponent,
    StripeSaveCreditCardComponent,
    CreditCardListComponent
  ],
  providers: [DatePipe]
})
export class SharedModule {}
